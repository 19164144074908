const imageList = [
    {
        src: require('./1.jpg'),
        title: 'The Land of Green Gold',
        caption: 'Eero Orbas is a tenth generation Sámi reindeer-herder living in Finland. He is passionate about the protection of indigenous rights and environmental protection, a leader in the struggle to continue traditional ways of life. The Sámi peoples have been fighting for sovereignty of land, culture, and language amidst an increasing number of environmental hardships.',
        credit: 'Copyright © Mirali Shukla. All rights reserved.',
    },
];

export default imageList;

/**
 {
        src: require('./2.jpg'),
        title: 'The Land of Green Gold',
        captions: 'A Swedish Sámi elder worries about the increasing cost of living a traditional way of life. "Life became more expensive and you have to start trying to do something to earn more." This cost is crippling and is forcing families into urban areas, or to take industry jobs that are depleting their own lands, in order to make ends meet. ',
        credit: 'Copyright © Mirali Shukla. All rights reserved.',
    },
 {
        src: require('./3.jpg'),
        title: 'The Land of Green Gold',
        captions: 'A Sámi elder, Nils Johannes Hotti, honors us by putting on his traditional costume and sitting for a portrait in his living room. "Time here is running short. We have about two decades before I fear reindeer herding will be extinct. In one cup there is our culture and in the other cup there’s money - but I feel like you can’t put a value on culture until it is gone."',
        credit: 'Copyright © Mirali Shukla. All rights reserved.',
    },
 {
        src: require('./4.jpg'),
        title: 'The Land of Green Gold',
        captions: 'Nils Johannes Hotti shows us a picture of him when he was three years old, over 70 years ago circa 1945 in Sweden. "A lot has changed in the past two generations. In the 60’s we used to still live in “Kota” [a traditional Sámi tent] all year round. All the family. It was a totally different kind of reindeer herding." To preserve traditional Sámi livelihoods, attention must be given to the old growth forests that the Sámi have called home in time immemorial.',
        credit: 'Copyright © Mirali Shukla. All rights reserved.',
    },
 {
        src: require('./5.jpg'),
        title: 'The Land of Green Gold',
        captions: 'Two Sámi men, father and son (L-R Eero Orbas, Eerik Mustonen) stand together in the far North of Finland. There are over 300 words for snow and ice in the Sámi language and a thousand terms for reindeer. Knowing the landscape and the natural environment is a generational linguistic gift. "It was totally forbidden even to speak about the Sámi culture. The church said that the Sámi language was language of devil, and in school if you said a Sàmi word, you had to stay two hours after school for every single word. I remember that in 1958, a priest came to see how well people knew the bible in Finnish. Even adults had to stand in the corner to shame them if they did not remember the bible. So everybody was afraid to speak about it."',
        credit: 'Copyright © Mirali Shukla. All rights reserved.',
    },

 {
        src: require('./6.jpg'),
        title: 'The Land of Green Gold',
        captions: 'Eero Orbas and his wife, Henna Orbas, stand outside their family home. They have concerns about the foresting industry. "The modern forest industry and especially practices with soil turning is problematic. When you turn the soil over, there is nothing, and I mean nothing, for reindeer to eat. The landscape in Lapland is so poor and dry that there is nothing growing on tilled land for years - or even decades - and when you turn the soil and all the rock is coming up. Sometimes you feel that you are driving in the moon, or on mars, and nothing will ever grow on rocks. And when you cut down all the trees, there is no more mushrooms in the autumn time for reindeer, because they live in symbiosis with the trees. So if there is no trees, there is no mushrooms and mushrooms are the most important thing for reindeers to gather some weight for the winter. If they don’t get their mushrooms, then there is going to trouble in the winter time keeping alive, so it is not so easy. We are open to responsible, sustainable forestry but in Finland, we feel as though the government is not even trying."',
        credit: 'Copyright © Mirali Shukla. All rights reserved.',
    },

 {
        src: require('./7.jpg'),
        title: 'The Land of Green Gold',
        captions: 'The passage of knowledge between generations of Sámi men. "It should not be always just about money, we should think also about nature, and all the people that are living from the nature. We can do both things, reindeer herding and forestry like we have been doing, until the modern heavy forest industry started. With unsustainable practices like clear cutting. If this modern greedy way of life is going to continue, reindeer herding will be soon gone. So will pure, wild and free Lapland. Soon there is only mining, paper industry, and some artificial “Sámi villages” for tourism industry left."',
        credit: 'Copyright © Mirali Shukla. All rights reserved.',
    },
 {
        src: require('./8.jpg'),
        title: 'The Land of Green Gold',
        captions: '"Why is reindeer-herding changing? I think it is because of climate change. Our climate is turning more wet, winters are getting warmer, and weather is getting more unpredictable.This year there came lots of snow in beginning of November then it was raining for couple of days and then came low temperatures (-10, -20 degrees C), so reindeers started to head south fast. We have to collect reindeer even from 200 km away."',
        credit: 'Copyright © Mirali Shukla. All rights reserved.',
    },
 {
        src: require('./9.jpg'),
        title: 'The Land of Green Gold',
        captions: '"Reindeers are eating and digging snow, like they have from beginning of time. Our family has been always been living from nature and reindeers and all the trouble started when the first mining industry started in Lapland. From then, reindeer herders have been forced to give always space for modern industry and industry have never been really negotiating with people who were here first. Is this right and is it sustainable? I think not."',
        credit: 'Copyright © Mirali Shukla. All rights reserved.',
    },
 {
        src: require('./10.jpg'),
        title: 'The Land of Green Gold',
        captions: 'Climate change has shifted weather patterns in arctic Europe and has increased the frequency of phenomena like blue ice. This makes it impossible for reindeer to forage as they naturally would through the winter months, and requires the reindeer-herder to feed their herd. "I believe this is the first year in history where most of us have to feed our reindeer year round. Trying to feed that many reindeer is like trying to put out a forest fire with a bucket."',
        credit: 'Copyright © Mirali Shukla. All rights reserved.',
    },
 */