import React, { useRef, useState } from 'react';

import useSlider from '../hooks/useSlider';

// clear current slide when component loads
sessionStorage.removeItem('i');

const Slider = ({images}) => {

    let slideImage = useRef(null);
    let slideTitle = useRef(null);
    let slideCaption = useRef(null);
    let slideCredit = useRef(null);

    const { goToPreviousSlide, goToNextSlide, currSlide } = useSlider(slideImage, slideTitle, slideCaption, slideCredit, images);

    const [isHide, setIsHide] = useState(true);

    const handleShow = () => {

        // save current slide so it can be reloaded when state changes and component is re-rendered
        sessionStorage.setItem('i', currSlide());

        setIsHide(!isHide);

    };

    return (

        <div className="slider" ref={slideImage}>

            <div className={"slider--content"}>

                <button onClick={goToPreviousSlide} className="slider__btn-left">
                    <i className="fas fa-angle-left"></i>
                </button>

                <div className={isHide ? "slider--feature":"hide"}>

                    <p ref={slideTitle} className="feature--title"></p>

                    <p ref={slideCaption} className="feature--text"></p>

                    <p ref={slideCredit} className="feature--text"></p>

                    <button onClick={handleShow} className="slider__btn-hide">
                        <i className="fas fa-times-circle"></i>
                    </button>

                </div>

                <button onClick={goToNextSlide} className="slider__btn-right">
                    <i className="fas fa-angle-right"></i>
                </button>

            </div>

            <button onClick={handleShow} className={!isHide ? "slider__btn-show":"hide"}>
                <i className="fas fa-info-circle"></i>
            </button>

        </div>

    );

}

export default Slider;