const imageList = [
    {
        src: require('./JHOUSTON_150313_02007.jpg'),
        title: 'Last Wildest Place',
        caption: 'Workers loading lumber on a truck at the port in Pucallpa on the Ucayali River in Peru. Pucallpa is well known as one of the centers for trafficking illegal lumber out of the Upper Amazon and a few weeks before this photograph the local paper reported that 4 million board feet of illegal lumber was confiscated at 8 Pucallpa sawmills.',
        credit: 'Copyright © 2015 Jason Houston. All rights reserved.',
        contact: 'jason@jasonhouston.com'
    },
    {
        src: require('./JHOUSTON_150313_02361.jpg'),
        title: 'Last Wildest Place',
        caption: 'Logs arriving at the sawmills at the port in Pucallpa on the Ucayali River in Peru. Pucallpa is well known as one of the centers for trafficking illegal lumber out of the Upper Amazon and a few weeks before this photograph the local paper reported that 4 million board feet of illegal lumber was confiscated at 8 Pucallpa sawmills.',
        credit: 'Copyright © 2015 Jason Houston. All rights reserved.'
    },
    {
        src: require('./JHOUSTON_150314_02955.jpg'),
        title: 'Last Wildest Place',
        caption: 'Girl at the port in Puerto Esperanza, Purús Province, Peru. The larger of the remote towns like Puerto Esperanza are the weak bridge between the even more remote indigenous villages and the rest of the world. They have mediocre healthcare, remedial education, infrequently replenished and limited provisions, few economic opportunities, and usually at least several western religious denominations vying for their souls. Some connect to the outside world by incomplete roads, others by rivers so long and so winding that travel on them is measured in days or even months, and distances determined in \'turns\' instead of kilometers. The 1500 people in Puerto Esperanza connect to their nearest urban neighbor, Santa Rosa in Brazil, via several days by boat down the Purús River. Some of the goods that stock the few small stores here come from Santa Rosa, though most come in on the much more expensive (and are more expensive because of it) chartered flights from Pucallpa.',
        credit: 'Copyright © 2015 Jason Houston. All rights reserved.'
    },
    {
        src: require('./JHOUSTON_150317_04928.jpg'),
        title: 'Last Wildest Place',
        caption: 'Young boy with temporary face tattoos (made using botanicals including huito) outside his home in Santa Rey, Peru on the Curanja River near the limit of the Purús Communal Reserve. Remote indigenous communities like Santa Rey and the communal reserves set up for their subsistence use serve as buffers for and gateways to more strictly protected areas such as the Alto Purús National Park and Mashco Piro Indigenous Reserve.',
        credit: 'Copyright © 2015 Jason Houston. All rights reserved.'
    },
    {
        src: require('./JHOUSTON_170505_00194.jpg'),
        title: 'Last Wildest Place',
        caption: 'A jaguar pelt selling for S/.300 (approximately US$90) found in Mercado Dos in Pucallpa. Pucallpa is the capital of the Ucayali region and a main hub for many things passing in and out of the western Amazon. We saw other pelts in his stall and asked what else he had for sale, photographing as he showed us various items.',
        credit: 'Copyright © 2015 Jason Houston. All rights reserved.'
    },
    {
        src: require('./JHOUSTON_170507_01280.jpg'),
        title: 'Last Wildest Place',
        caption: 'Miluska with pet howler monkey in Nueva Victoria II, Yurúa River, Ucayali Province, Peru. Miluska\'s Chitonahua tribal group lives in initial contact in their own community of Nueva Victoria II. named after the Ashaninka community of Nueva Victoria where they first settled after contact.',
        credit: 'Copyright © 2015 Jason Houston. All rights reserved.'
    },
    {
        src: require('./JHOUSTON_170510_07366.jpg'),
        title: 'Last Wildest Place',
        caption: 'A family eats a land tortoise cooked in its shell while one of the women plucks a jungle fowl. Nuevo Eden, a Ashéninka village far up the Yurúa River, has several families with direct experience with isolated tribes.',
        credit: 'Copyright © 2015 Jason Houston. All rights reserved.'
    },
    {
        src: require('./JHOUSTON_170511_08415.jpg'),
        title: 'Last Wildest Place',
        caption: 'Cleaning a small forest parrot in preparation for a meal. This Chitonahua tribal group was contacted in the mid-1990s by loggers working illegally in their territory. Men were forced into debt and forced to work it off, essentially in a form of indentured servitude. In the early 2000\'s they escaped and ultimately were granted permission to settle near the Ashaninka community of Nueva Victoria where they named their own community Nueva Victoria II.',
        credit: 'Copyright © 2015 Jason Houston. All rights reserved.'
    },
    {
        src: require('./JHOUSTON_170511_08564.jpg'),
        title: 'Last Wildest Place',
        caption: '[NAME WITHHELD BY REQUEST] in Nueva Victoria II on the Yurua River, Peru, was attacked by a jaguar while working night watch in a logging camp. In the confusion the jaguar dropped him and dragged off a bloody backpack instead of him, which spared his life. The Chitonahua tribal group he lives with were forcibly contacted in the mid 1990’s by mahogany loggers who grew tired of the Chitonahua raiding their camps.',
        credit: 'Copyright © 2015 Jason Houston. All rights reserved.'
    },
    {
        src: require('./JHOUSTON_170512_08987.jpg'),
        title: 'Last Wildest Place',
        caption: 'A man searches for his keys dropped in the water and mud at the port on the river in Puerto Breu, Ucayali region, Peru. The 500 people in Puerto Breu connect to their nearest neighbor, the also mostly-unconnected but slightly larger Marechal Thaumaturgo in Brazil, via 9 hours by boat down the Yurúa. It’s where most of the goods that stock the 5 small stores here come from as it’s easier—and a lot cheaper—than flying goods in on the couple-times-a-week flights from Pucallpa.',
        credit: 'Copyright © 2015 Jason Houston. All rights reserved.'
    },
    {
        src: require('./JHOUSTON_170512_10334.jpg'),
        title: 'Last Wildest Place',
        caption: 'Returning from the river with a large catfish in Puerto Breu, Ucayali region, Peru. This catch, like most such windfall harvests in these small tribally-organized communities, will be shared widely with the local community.',
        credit: 'Copyright © 2015 Jason Houston. All rights reserved.'
    },
    {
        src: require('./JHOUSTON_170516_11676.jpg'),
        title: 'Last Wildest Place',
        caption: 'Waiting on the plane in Puerto Esperanza, Peru. Located in a remote southeastern corner of Peru against the border with Brazil and surrounded by protected areas, Puerto Esperanza is both isolated as well as a hub for goods and services for the even more remote indigenous communities in the Alto Purús region.',
        credit: 'Copyright © 2015 Jason Houston. All rights reserved.'
    },
    {
        src: require('./JHOUSTON_170521_17306.jpg'),
        title: 'Last Wildest Place',
        caption: 'Maria, a Chitonahua woman in initial contact in the Purús Communal Reserve on the upper Curanja River, Peru, spends her days hanging around her makeshift camp behind a government control post near the Mashco - Piro Indigenous Reserve where she begs for food and medicine from guards and nearby villagers. The government\'s policy is to provide only minimal support to people in initial contact in order to prevent dependency, leaving people like Maria alone to navigate the assimilation process.',
        credit: 'Copyright © 2015 Jason Houston. All rights reserved.'
    },
    {
        src: require('./JHOUSTON_170521_18112.jpg'),
        title: 'Last Wildest Place',
        caption: 'Boy with toy gun in Colombiana, Ucayali Province, Peru. The remote indigenous communities of Alto Purús region live in the difficult midst of traditional culture and western progress.',
        credit: 'Copyright © 2015 Jason Houston. All rights reserved.'
    },
    {
        src: require('./JHOUSTON_170522_18604.jpg'),
        title: 'Last Wildest Place',
        caption: 'Afternoon futbol in Miguel Grau near the confluence of the Purús and Curanja Rivers in Ucayali Province in southeastern Peru. Communities that live near river confluences and along tributaries in the remote upper Amazon often have interactions with migrating isolated tribes who also use the waterways for moving through the forests.',
        credit: 'Copyright © 2015 Jason Houston. All rights reserved.'
    },
    {
        src: require('./JHOUSTON_170524_20192.jpg'),
        title: 'Last Wildest Place',
        caption: 'Evening traffic in front of some small shops in Puerto Esperanza, Peru. The larger of the remote towns like Puerto Esperanza are the weak bridge between the even more remote indigenous villages and the rest of the world. They have mediocre healthcare, remedial education, infrequently replenished and limited provisions, few economic opportunities, and usually at least several western religious denominations vying for their souls. Some connect to the outside world by incomplete roads, others by rivers so long and so winding that travel on them is measured in days or even months, and distances determined by \'turns\' instead of kilometers. The 1500 people in Puerto Esperanza connect to their nearest urban neighbor, Santa Rosa in Brazil, via several days by boat down the Purús River. Some of the goods that stock the few small stores here come from Santa Rosa, though most come in on the much more expensive (and are more expensive because of it) chartered flights from Pucallpa.',
        credit: 'Copyright © 2015 Jason Houston. All rights reserved.'
    },
    {
        src: require('./JHOUSTON_170526_23070.jpg'),
        title: 'Last Wildest Place',
        caption: 'Aerial view of an approaching storm over the vast roadless forests of the Alto Purús region in Peru. The Purús/Manu complex in southeastern Peru including two national parks, multiple indigneious  reserves, and other protected areas is one of the most remote, inaccessible, and important areas of the Amazon, where still-intact ecosystems provide sustenance for indigenous communities and is home to some of the last isolated “uncontacted” tribes on earth. Photographed from a low-flying, single engine airplane.',
        credit: 'Copyright © 2015 Jason Houston. All rights reserved.'
    },
    {
        src: require('./JHOUSTON_180720_3461.jpg'),
        title: 'Last Wildest Place',
        caption: 'Leaving for a half-day hunting and gathering trip through an area cleared for subsistence agriculture near the remote village of Selva Virgen on the remote upper Yurúa River, Peru. Remote indigenous communities like Selva Virgen are the gateways to—and stewards of—more strictly protected areas such as the Alto Purús National Park and Murunahua Indigenous Reserve.',
        credit: 'Copyright © 2018 Jason Houston. All rights reserved.'
    },
    {
        src: require('./JHOUSTON_180312_06014.jpg'),
        title: 'Last Wildest Place',
        caption: 'Stars in a near perfectly dark sky over a remote indigenous village on the Purús River in Brazil on the border of Peru show how remote some areas of the Alto Purús region are.',
        credit: 'Copyright © 2018 Jason Houston. All rights reserved.'
    },
    {
        src: require('./JHOUSTON_180315_07612.jpg'),
        title: 'Last Wildest Place',
        caption: 'The charred hind quarter of an endangered black spider monkey hangs at a butcher in Puerto Esperanza, Purús Province, Peru. Puerto Esperanza is the remote capital of the Purús region where indigenous culture and subsistence lifestyles mix with western progress. No roads connect it and there are few regular commercial flights—access is by river or the occasional government or charter planes.',
        credit: 'Copyright © 2018 Jason Houston. All rights reserved.'
    },
    {
        src: require('./JHOUSTON_180315_08579.jpg'),
        title: 'Last Wildest Place',
        caption: 'Marcelino Pinedo Cecilio\'s community was first contacted in the 1950s by an anthropologist when he was a child. Soon after, many in his community developed sore throats and fevers, and Marcelino says many died from what would have been common western diseases as a result of this contact. This dynamic of contact, disease, and then death too often defines the relationships between indigenous Amazonian people and outsiders, starting with the first Spanish and Portuguese explorers in the 16th century and continuing today with missionaries, loggers, drug traffickers—and too-eager journalists—who continue the trend.',
        credit: 'Copyright © 2018 Jason Houston. All rights reserved.'
    },
    {
        src: require('./JHOUSTON_190920_00501.jpg'),
        title: 'Last Wildest Place',
        caption: 'Aerial drone photograph showing the extent of the deforestation and environmental impact from illegal and unregulated alluvial gold mining in the tropical forests of the Madre de Dios region in Southeastern Peru. The La Pampa ‘scar’ covers approximately 40,000 hectares where miners tapped local rivers and wetlands to liquify and sort soil to extract gold within what was some of the most biodiverse forests in the world. The illicit industry also drives a host of humanitarian crises including slavery, sex trafficking, health related environmental justice, and indigenous rights violations. In February 2019, Peru assigned 1500 police and military personnel to the La Pampa region in a historic crackdown on gold mining in this region. ',
        credit: 'Copyright © 2018 Jason Houston. All rights reserved.'
    },
    {
        src: require('./JHOUSTON_191002_02001.jpg'),
        title: 'Last Wildest Place',
        caption: 'A monkey skull found in a mud bank on the Mapuya River, Atalaya Province, Peru.',
        credit: 'Copyright © 2018 Jason Houston. All rights reserved.'
    },
    {
        src: require('./JHOUSTON_191003_02443.jpg'),
        title: 'Last Wildest Place',
        caption: 'An ocelot killed by new settlers on the upper Inuya River, Peru, because they said \'they were afraid’. These invaders, many in this area arriving from coca-growing regions in the Andean foothills to the west, are less connected to and less invested in any type of ecological balance with the lowland jungles than the indigenous groups who have lived there for millennia.',
        credit: 'Copyright © 2018 Jason Houston. All rights reserved.'
    },
    {
        src: require('./JHOUSTON_191003_02480.jpg'),
        title: 'Last Wildest Place',
        caption: 'Jovita Rengifo Cobos\'s from Imaculada on the Inuya River in Peru tells how her parents grew up enslaved in the rubber and agriculture booms of the early 1900\'s that spread across the amazon, forced to work growing barbasco—the common name of several plants that contain poisonous chemical compounds used for fishing by indigenous populations and that became important to the pharmaceuticals industry. From gold and the earliest Spanish and Portuguese explorers; to rubber and animal skins in the early 1900s; to oil and gas, timber, cattle, and cocaine today, access to and the extraction of natural resources at the expense of the indigenous populations and the ecosystems they rely on has defined life in the Amazon.',
        credit: 'Copyright © 2018 Jason Houston. All rights reserved.'
    },
    {
        src: require('./JHOUSTON_191009_03903.jpg'),
        title: 'Last Wildest Place',
        caption: 'Linder Miques Viquia, Sepahua Community Vigilance Committee Member patrolling the upper Sepahua River, Peru where illegal land grab schemes are leading to rapid deforestation threatening the indigenous communities and unparalleled biodiversity of multiple critical protected areas.',
        credit: 'Copyright © 2018 Jason Houston. All rights reserved.'
    },
    {
        src: require('./JHOUSTON_191010_04793.jpg'),
        title: 'Last Wildest Place',
        caption: 'Carlitos (name unverified and likely false) and his family are new arrivals from the coca-growing highlands to the west and are moving in on land they have freshly cleared for agriculture on the upper Sepahua River, Peru. These plots, increasing in numbers almost daily, are illegal, unknown and unrecognized by officials, and mostly for small farms that appear to be fronts for growing coca deeper in the forests.',
        credit: 'Copyright © 2018 Jason Houston. All rights reserved.'
    },
    {
        src: require('./JHOUSTON_191011_05057.jpg'),
        title: 'Last Wildest Place',
        caption: 'Aerial drone photograph of deforestation for agriculture near Santa Isabelle de Sepahua, Sepahua River, Peru. In the past year, new deforestation has been showing up in satellite imagery along remote tributaries in the Urubamba watershed in southeastern Peru.',
        credit: 'Copyright © 2018 Jason Houston. All rights reserved.'
    },
    {
        src: require('./JHOUSTON_191011_05154.jpg'),
        title: 'Last Wildest Place',
        caption: 'A family from the valley of the three rivers Apurimac, Ene and Mantaro (aka, VRAEM or VRAE)—one of the main areas for cultivation of coca in Peru—arriving to establish a new settlement on the Sepahua River. Families such as this one are driven out of VRAEM as authorities crack down on illegal drug production and are moving into the remote lowland jungles, often staking claim to plots of land through illegal land grab schemes.',
        credit: 'Copyright © 2018 Jason Houston. All rights reserved.'
    },
    {
        src: require('./JHOUSTON_191014_06043.jpg'),
        title: 'Last Wildest Place',
        caption: 'Coca leaves for sale at the port in Atalaya, Peru. Coca is a familiar traditional medicine chewed to relieve hunger and fatigue and to enhance physical performance, as well as for stimulating stomach function, treating asthma, colds, and other ailments. The leaves are found for sale in many small shops and markets in Peru from the Andes down into the upper Amazon basin. Cultivation for cocaine production also drives a powerful and environmentally destructive (i.e., clearing remote forests for crops) and illegal industry.',
        credit: 'Copyright © 2018 Jason Houston. All rights reserved.'
    },
];

export default imageList;