import React, { Component } from 'react';
import Slider from '../../components/Slider'
import Images from '../../assets/shukla_mirali/shukla_mirali'

export default class shuklaMiraliArtist extends Component {

    render() {

        return (

            <Slider images={Images}/>

        );

    }

}

