import React, { Component } from 'react';
import Slider from '../../components/Slider'
import Images from '../../assets/conservation_international/conservation_international'

export default class conservationInternationalArtist extends Component {

    render() {

        return (

            <Slider images={Images}/>

        );

    }

}

