const imageList = [
    {
        src: require('./MichaelChew-0145.jpg'),
        title: 'Images and Stories from the Borders: <br> Sundarbans and Thar Regions',
        caption: 'A woman gathers precious drinking water in her street. Villagers in Hinta Village, Rajasthan, western India, are concerned about their water scarcity and quality including contamination that many believe is causing an increasingly high incidence of joint and heart disease. Drinking water here comes from groundwater accessed through hand pumps, open wells, or tubewells, however there are many issues with water infrastructure. While some farmers are keen to adopt new technologies that might help, water scarcity, over-extraction, and under-developed water-governance that led to scarcity and crop failures, makes this difficult overall.',
        credit: 'Copyright © 2014 Michael Chew. All rights reserved.',
    },
    {
        src: require('./MichaelChew-4543.jpg'),
        title: 'Images and Stories from the Borders: <br> Sundarbans and Thar Regions',
        caption: 'School children play at a water pump in Hinta Village, Rajasthan, western India. Groundwater scarcity is an increasing challenge for this mixed community of Brahmins, Rajputs and Vaishya, most of whom rely on farming in one way or another for their livelihoods. The increasing complexity of conflicts between water users calls for deeper participatory solutions, rather than shorter term technical fixes through engineering or top-down policymaking alone. Groundwater diplomacy – sharing the water resources on a sustainable basis through village-level negotiations and conflict management – can harmonise the groundwater availability and needs of village communities.  Bhujal Jankaars are a network of specially trained farmers working together to come up with village-level understanding of these situations and bridge the divide between local indigenous and external technical knowledge systems. They can advocate for locally relevant improvement strategies that are owned and implemented by villagers themselves.',
        credit: 'Copyright © 2014 Michael Chew. All rights reserved.',
    },
    {
        src: require('./MichaelChew-3758.jpg'),
        title: 'Images and Stories from the Borders: <br> Sundarbans and Thar Regions',
        caption: 'Fishermen casting their morning nets south of Khula in the Sunderbans, the world’s largest mangrove forest bordering Bangladesh and India. It is home to a number of endangered plants and animals, including the majestic Bengal tiger.  Bangladesh’s low-lying coastline is extremely vulnerable to violent tropical storms and cyclones, and the frequency and severity of such events is increasing due to climate change.',
        credit: 'Copyright © 2011 Michael Chew. All rights reserved.',
    },
    {
        src: require('./MichaelChew-3972.jpg'),
        title: 'Images and Stories from the Borders: <br> Sundarbans and Thar Regions',
        caption: 'A local farmer and PhotoVoice participant in Sundapera Village, Rajasthan, photographs his community. The India-Pakistan border region which Rajasthan forms part of can have high geo-political tensions, exacerbated by resource shortages including groundwater. The joint Australia-India MARVI (Managing Aquifer Recharge and Sustaining Groundwater Use through Village-level Intervention) project was developed to improve the security of irrigation water supplies and enhance livelihood opportunities for rural communities. Participatory photography was included in this project to complement quantitative data collection, by providing ways of directly exploring the villagers’ relationship to their groundwater, and honouring their local voices. This approach can empower participants to value their own unique water knowledges and enable them to share this with others across economic and socio-cultural divides.',
        credit: 'Copyright © 2011 Michael Chew. All rights reserved.',
    },
    {
        src: require('./MichaelChew-4388.jpg'),
        title: 'Images and Stories from the Borders: <br> Sundarbans and Thar Regions',
        caption: 'Sunset illuminates an intricate network of mangrove roots in the Bangladeshi Sunderbans, providing a haven for biodiversity, livelihoods for villages, and protection from tidal surges. The Sundabans are the world\'s largest mangrove forest, a labyrinth of waterways and tangled vines that are home to vast numbers of endangered plants and animals. Although these mangroves offer the communities living here some protection from tropical storms and cyclones, these ecosystems are threatened by deforestation and increasing population pressures.',
        credit: 'Copyright © 2011 Michael Chew. All rights reserved.',
    },
];

export default imageList;