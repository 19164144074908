import { useEffect } from 'react'

let slideCounter = 0;

const useSlider = (slideImage, slideTitle, slideCaption, slideCredit, images) => {

    useEffect(() => startSlider());

    const startSlider = () => {

        handleSlide(0)

    }

    const handleSlide = (slide) => {

        let i;

        if (slide) {
            i = slide;
        } else if (sessionStorage.getItem('i')) {
            i = sessionStorage.getItem('i');
        } else {
            i = 0;
        };

        slideImage.current.style.backgroundImage = `url(${images[i].src})`;
        slideTitle.current.innerHTML = images[i].title;
        slideCaption.current.innerHTML = images[i].caption;
        slideCredit.current.innerHTML = images[i].credit;

    }

    const goToPreviousSlide = () => {

        if (slideCounter === 0) {

            slideCounter = images.length - 1;
            handleSlide(slideCounter);

        } else {

            slideCounter--;
            handleSlide(slideCounter);

        }

    }

    const goToNextSlide = () => {

        if (slideCounter === images.length - 1) {

            slideCounter = 0;
            handleSlide(slideCounter)

        } else {

            slideCounter++;
            handleSlide(slideCounter);

        }

    }

    const currSlide = () => {

        return slideCounter;

    }

    return { startSlider, goToPreviousSlide, goToNextSlide, currSlide }

}

export default useSlider;