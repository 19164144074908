const imageList = [
    {
        src: require('./ci_21375266_Large.jpg'),
        title: 'Caring for Nature: Women in Conservation',
        caption: 'In 2017, the Raja Ampat Regency Government established the Fam Island Marine Protected Area in partnership with Indigenous Papuan community leaders. The benefits of this protected area are wide-reaching: replenished fish stocks; buffers against climate change; and sustainable livelihoods from coconut oil and coconut soap production.',
        credit: 'Copyright © Conservation International. All rights reserved.',
    },
    {
        src: require('./ci_36252354_Large.jpg'),
        title: 'Caring for Nature: Women in Conservation',
        caption: 'In 2017, the Raja Ampat Regency Government established the Fam Island Marine Protected Area in partnership with Indigenous Papuan community leaders. The benefits of this protected area are wide-reaching: replenished fish stocks; buffers against climate change; and sustainable livelihoods from coconut oil and coconut soap production.',
        credit: 'Copyright © Conservation International. All rights reserved.',
    },
    {
        src: require('./ci_87755473_Large.jpg'),
        title: 'Caring for Nature: Women in Conservation',
        caption: 'In 2017, the Raja Ampat Regency Government established the Fam Island Marine Protected Area in partnership with Indigenous Papuan community leaders. The benefits of this protected area are wide-reaching: replenished fish stocks; buffers against climate change; and sustainable livelihoods from coconut oil and coconut soap production.',
        credit: 'Copyright © Conservation International. All rights reserved.',
    },
    {
        src: require('./ci_91369456_Large.jpg'),
        title: 'Caring for Nature: Women in Conservation',
        caption: 'In 2017, the Raja Ampat Regency Government established the Fam Island Marine Protected Area in partnership with Indigenous Papuan community leaders. The benefits of this protected area are wide-reaching: replenished fish stocks; buffers against climate change; and sustainable livelihoods from coconut oil and coconut soap production.',
        credit: 'Copyright © Conservation International. All rights reserved.',
    },
    {
        src: require('./ci_13481748_Large.jpg'),
        title: 'Caring for Nature: Women in Conservation',
        caption: 'After three decades of conflict, Timor-Leste became an independent nation in 2002, making it one of the world’s youngest countries. But growing pains — humanitarian, economic and environmental — endure. Though small, the island boasts exceptional biodiversity, extensive fisheries and thriving coral reefs. Preserving these bounties will be key to the health, prosperity and development of Timorese communities.',
        credit: 'Copyright © Conservation International. All rights reserved.',
    },
    {
        src: require('./ci_75945399_Large.jpg'),
        title: 'Caring for Nature: Women in Conservation',
        caption: 'After three decades of conflict, Timor-Leste became an independent nation in 2002, making it one of the world’s youngest countries. But growing pains — humanitarian, economic and environmental — endure. Though small, the island boasts exceptional biodiversity, extensive fisheries and thriving coral reefs. Preserving these bounties will be key to the health, prosperity and development of Timorese communities.',
        credit: 'Copyright © Conservation International. All rights reserved.',
    },
    {
        src: require('./ci_79381925_Large.jpg'),
        title: 'Caring for Nature: Women in Conservation',
        caption: 'After three decades of conflict, Timor-Leste became an independent nation in 2002, making it one of the world’s youngest countries. But growing pains — humanitarian, economic and environmental — endure. Though small, the island boasts exceptional biodiversity, extensive fisheries and thriving coral reefs. Preserving these bounties will be key to the health, prosperity and development of Timorese communities.',
        credit: 'Copyright © Conservation International. All rights reserved.',
    },
    {
        src: require('./ci_34982568_Large.jpg'),
        title: 'Caring for Nature: Women in Conservation',
        caption: 'Conservation International 2020 Indigenous Leaders Conservation Fellow, Kenya. Rufo Roba Halakhe hails from the Jaldesa conservancy in eastern Kenya. There, three ethnic groups, two religions and two divergent lifestyles — pastoralism and agriculture — coexist, but not without conflict. Rufo studies how tribal clashes disproportionately affect women — and how women can play a key role in peacebuilding.',
        credit: 'Copyright © Conservation International. All rights reserved.',
    },
    {
        src: require('./ci_78637292_Large.jpg'),
        title: 'Caring for Nature: Women in Conservation',
        caption: 'Kenya’s Reteti Elephant Sanctuary — the first community-owned elephant sanctuary in East Africa — provides refuge for elephants injured by poaching. Through a partnership with Conservation International, Reteti focuses on rescue and return where possible, with the goal of reuniting calves with their mothers within 48 hours.',
        credit: 'Copyright © Conservation International. All rights reserved.',
    },
];

export default imageList;