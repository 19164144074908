const imageList = [
    {
        src: require('./Guardianas_Ruedaphotos-1.jpg'),
        title: 'Guardians',
        caption: 'Cedepo, Florencio Varela. Province of Buenos Aires.',
        credit: 'Copyright © 2021 Daiana Valencia and Celeste Alonso. All rights reserved.',
    },
    {
        src: require('./Guardianas_Ruedaphotos-2.jpg'),
        title: 'Guardians',
        caption: 'One of the greenhouses in Cedepo\'s plot (a space where research, education and agroecological production programs are developed) where Minka Semillera (a group of organized women, guardians of the seeds) meet every week.',
        credit: 'Copyright © 2021 Daiana Valencia and Celeste Alonso. All rights reserved.',
    },
    {
        src: require('./Guardianas_Ruedaphotos-3.jpg'),
        title: 'Guardians',
        caption: 'Alicia is one of the members of Minka Semillera and is a Popular Educator. She is in charge of the project of the Cedepo Plot, where La casa de la Semillas (a space where seeds are stored and conserved) is located. It also serves as a meeting place for Minka Semillera. Every year the women of Minka organize themselves and decide which seeds each one will sow in her plot. Lot 85, year 2021, Cilantro seeds saved in recycled jars.',
        credit: 'Copyright © 2021 Daiana Valencia and Celeste Alonso. All rights reserved.',
    },
    {
        src: require('./Guardianas_Ruedaphotos-4.jpg'),
        title: 'Guardians',
        caption: 'Minka Semillera is made up of women producers (Alicia, Julia, Victoria, Silvia and Copa (an umbrella organization of Women and Dissidences). They keep an annual follow-up of each seed they sow, its varieties, characteristics, and everything related to that year of cultivation.',
        credit: 'Copyright © 2021 Daiana Valencia and Celeste Alonso. All rights reserved.',
    },
    {
        src: require('./Guardianas_Ruedaphotos-5.jpg'),
        title: 'Guardians',
        caption: 'Sowing field, at Cedepo\'s plot. Florencio Varela, Province of Buenos Aires.',
        credit: 'Copyright © 2021 Daiana Valencia and Celeste Alonso. All rights reserved.',
    },
    {
        src: require('./Guardianas_Ruedaphotos-6.jpg'),
        title: 'Guardians',
        caption: 'Alicia walks through the soil turned the day before for\n planting, which is planned for mid-September, the spring season. Florencio Varela. Province of Buenos Aires.\n',
        credit: 'Copyright © 2021 Daiana Valencia and Celeste Alonso. All rights reserved.',
    },
    {
        src: require('./Guardianas_Ruedaphotos-7.jpg'),
        title: 'Guardians',
        caption: 'Knife, one of the tools used in lettuce planting. Florencio Varela, Province of Buenos Aires.',
        credit: 'Copyright © 2021 Daiana Valencia and Celeste Alonso. All rights reserved.',
    },
    {
        src: require('./Guardianas_Ruedaphotos-8.jpg'),
        title: 'Guardians',
        caption: 'Tool where seeds are germinated before being transplanted into the soil.',
        credit: 'Copyright © 2021 Daiana Valencia and Celeste Alonso. All rights reserved.',
    },
    {
        src: require('./Guardianas_Ruedaphotos-9.jpg'),
        title: 'Guardians',
        caption: 'The last chard in Julia\'s Greenhouse. Florencio Varela. Province of Buenos Aires.',
        credit: 'Copyright © 2021 Daiana Valencia and Celeste Alonso. All rights reserved.',
    },
    {
        src: require('./Guardianas_Ruedaphotos-10.jpg'),
        title: 'Guardians',
        caption: 'Julia has been living in Florencio Varela for more than 30 years. She joined Minka Semillera as an independent producer. She lives with her husband and many dogs. Arugula. 2021. Florencio Varela. Province of Buenos Aires.',
        credit: 'Copyright © 2021 Daiana Valencia and Celeste Alonso. All rights reserved.',
    },
    {
        src: require('./Guardianas_Ruedaphotos-11.jpg'),
        title: 'Guardians',
        caption: 'Fridays is one of the days that the women of Minka semillera meet at the Cedepo plot to separate the seeds, weigh them and add the identification label for their subsequent sale. They assemble seed conservation batches and seed sale batches. Florencio Varela. Province of Buenos Aires.',
        credit: 'Copyright © 2021 Daiana Valencia and Celeste Alonso. All rights reserved.',
    },
    {
        src: require('./Guardianas_Ruedaphotos-12.jpg'),
        title: 'Guardians',
        caption: 'Different types of seeds, saved for conservation.',
        credit: 'Copyright © 2021 Daiana Valencia and Celeste Alonso. All rights reserved.',
    },
    {
        src: require('./Guardianas_Ruedaphotos-13.jpg'),
        title: 'Guardians',
        caption: 'Julia\'s Greenhouse. Florencio Varela. Province of Buenos Aires.',
        credit: 'Copyright © 2021 Daiana Valencia and Celeste Alonso. All rights reserved.',
    },
    {
        src: require('./Guardianas_Ruedaphotos-14.jpg'),
        title: 'Guardians',
        caption: 'Victoria is a farmer and belongs to the APF Varela cooperative. There she met Alicia and after working together for several years they joined with the other farmers to form Minka Semillera. Galician Lettuce. Florencio Varela. Province of Buenos Aires.',
        credit: 'Copyright © 2021 Daiana Valencia and Celeste Alonso. All rights reserved.',
    },
    {
        src: require('./Guardianas_Ruedaphotos-15.jpg'),
        title: 'Guardians',
        caption: 'Seedlings in Julia\'s greenhouse. As part of the cultivation process before moving to direct soil, seedlings are assembled with tomato seeds or aromatic plants, some are sown and harvested and others are used for sale. Florencio Varela. Province of Buenos Aires.',
        credit: 'Copyright © 2021 Daiana Valencia and Celeste Alonso. All rights reserved.',
    },
    {
        src: require('./Guardianas_Ruedaphotos-16.jpg'),
        title: 'Guardians',
        caption: 'Silvia is an extensionist technician for the Pro Huerta Program (INTA/MDSN), which seeks to improve the quality of life of the most vulnerable sectors, their food security and sovereignty, as well as other environmental, health and education issues. She is also one of the members of Minka Semillera. Leek. Lot 40 2020. Cup. Florencio Varela. Province of Buenos Aires.',
        credit: 'Copyright © 2021 Daiana Valencia and Celeste Alonso. All rights reserved.',
    },
    {
        src: require('./Guardianas_Ruedaphotos-17.jpg'),
        title: 'Guardians',
        caption: 'One of the greenhouses at Cedepo, a place for medicinal and aromatic plants.',
        credit: 'Copyright © 2021 Daiana Valencia and Celeste Alonso. All rights reserved.',
    },
    {
        src: require('./Guardianas_Ruedaphotos-18.jpg'),
        title: 'Guardians',
        caption: 'One of the greenhouses at Cedepo, a place for medicinal and aromatic plants.',
        credit: 'Copyright © 2021 Daiana Valencia and Celeste Alonso. All rights reserved.',
    },

];

export default imageList;