import React, { Component } from 'react';
import Slider from '../../components/Slider'
import Images from '../../assets/rueda_photos/rueda_photos'

export default class ruedaPhotosArtist extends Component {

    render() {

        return (

            <Slider images={Images}/>

        );

    }

}

